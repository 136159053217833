.box {
    position: relative;
    width: 400px;
    height: 250px;
    margin: 50px auto;
    padding-top: 50px; /* Space for the circle */
    background: rgba(255, 255, 255, 0.9);
  
    border-radius: 10px;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    box-sizing: border-box;
}

.circle {
    position: absolute;
    top: -25px; /* Half of the circle's height */
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 80px;
    background: rgba(252,191,212,1);
    color: #000000;
    display: flex;
    font-size: 2rem;
    font-family: "Assistant";
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0 5px 1px rgba(63, 61, 61, 0.8);
}

.content {
    padding: 20px;
}

.title {
    font-family: 'Assistant';
    font-size: 1.5rem;
    margin: 0 0 10px 0;
    color: #000000;
}

.description {
    font-family: 'AssistantR';
    font-size: 1.25rem;
    color: #000000;
}
@media only screen and (max-width: 350px){

    .box {
        position: relative;
        width: 300px;
        height: 180px;
        margin: 30px auto;
        padding-top: 30px; /* Space for the circle */
        background: rgba(255, 255, 255, 0.9);
      
        border-radius: 10px;
        backdrop-filter: blur(50px);
        -webkit-backdrop-filter: blur(50px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
        box-sizing: border-box;
    }
    
    .circle {
        position: absolute;
        top: -25px; /* Half of the circle's height */
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 60px;
        background: rgba(252,191,212,1);
        color: #000000;
        display: flex;
        font-size: 1.5rem;
        font-family: "Assistant";
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-shadow: 0 0 5px 1px rgba(63, 61, 61, 0.8);
    }
    
    .content {
        padding: 20px;
    }
    
    .title {
        font-family: 'Assistant';
        font-size: 1.2rem;
        margin: 0 auto 0;
        width:90%;
        color: #000000;
    }
    
    .description {
        font-family: 'AssistantR';
        font-size: 1rem;
        color: #000000;
    }
}
@media only screen  and (min-width: 450px) and (max-width: 550px){

    .box {
        position: relative;
        width: 350px;
        height: 210px;
        margin: 30px auto;
        padding-top: 30px; /* Space for the circle */
        background: rgba(255, 255, 255, 0.9);
      
        border-radius: 10px;
        backdrop-filter: blur(50px);
        -webkit-backdrop-filter: blur(50px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
        box-sizing: border-box;
    }
    
    .circle {
        position: absolute;
        top: -25px; /* Half of the circle's height */
        left: 50%;
        transform: translateX(-50%);
        width: 70px;
        height: 70px;
        background: rgba(252,191,212,1);
        color: #000000;
        display: flex;
        font-size: 1.7rem;
        font-family: "Assistant";
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-shadow: 0 0 5px 1px rgba(63, 61, 61, 0.8);
    }
    
    .content {
        padding: 20px;
    }
    
    .title {
        font-family: 'Assistant';
        font-size: 1.4rem;
        margin: 0 auto 0;
        width:90%;
        color: #000000;
    }
    
    .description {
        font-family: 'AssistantR';
        font-size: 1.1rem;
        color: #000000;
    }
}
@media only screen  and (min-width: 350px) and (max-width: 450px){

    .box {
        position: relative;
        width: 330px;
        height: 200px;
        margin: 30px auto;
        padding-top: 30px; /* Space for the circle */
        background: rgba(255, 255, 255, 0.9);
      
        border-radius: 10px;
        backdrop-filter: blur(50px);
        -webkit-backdrop-filter: blur(50px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;
        box-sizing: border-box;
    }
    
    .circle {
        position: absolute;
        top: -25px; /* Half of the circle's height */
        left: 50%;
        transform: translateX(-50%);
        width: 65px;
        height: 65px;
        background: rgba(252,191,212,1);
        color: #000000;
        display: flex;
        font-size: 1.6rem;
        font-family: "Assistant";
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-shadow: 0 0 5px 1px rgba(63, 61, 61, 0.8);
    }
    
    .content {
        padding: 20px;
    }
    
    .title {
        font-family: 'Assistant';
        font-size: 1.25rem;
        margin: 0 auto 0;
        width:90%;
        color: #000000;
    }
    
    .description {
        font-family: 'AssistantR';
        font-size: 1.1rem;
        color: #000000;
    }
}