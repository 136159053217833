.title{
    font-size:3.5rem;
    font-family: "AssistantR";
    text-align: center;
    margin:2% auto;
    direction:rtl;
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
}
.description{
    font-size:1.5rem;
    font-family: "AssistantR";
    text-align: center;
    margin:1% auto;
    direction: rtl;
    width:40%;
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
}
.row{
    display: flex;
    flex-direction: row;
    direction: rtl;
    flex-wrap: wrap;
}

@media only screen and (max-width: 350px){
    .title{
        font-size:2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
    }
    .description{
        font-size:1.1rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        width:80%;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
    }
    .row{
        display: flex;
        flex-direction: row;
        direction: rtl;
    
    }
}
@media only screen  and (min-width: 350px) and (max-width: 450px){
    .title{
        font-size:2.3rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
    }
    .description{
        font-size:1.2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        width:80%;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
    }
    .row{
        display: flex;
        flex-direction: row;
        direction: rtl;
    
    }
}
@media only screen  and (min-width: 450px) and (max-width: 550px){
    .title{
        font-size:2.5rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
    }
    .description{
        font-size:1.2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        width:80%;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
    }
    .row{
        display: flex;
        flex-direction: row;
        direction: rtl;
    
    }
}
@media only screen  and (min-width: 550px) and (max-width: 650px){
    .title{
        font-size:2.8rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
    }
    .description{
        font-size:1.2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        width:80%;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
    }
    .row{
        display: flex;
        flex-direction: row;
        direction: rtl;
    
    }
}
@media only screen  and (min-width: 650px) and (max-width: 750px){
    .title{
        font-size:3rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
    }
    .description{
        font-size:1.2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        width:80%;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
    }
    .row{
        display: flex;
        flex-direction: row;
        direction: rtl;
    
    }
}
@media only screen  and (min-width: 750px) and (max-width: 950px){
    .title{
        font-size:3.2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
    }
    .description{
        font-size:1.2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        width:80%;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
    }
    .row{
        display: flex;
        flex-direction: row;
        direction: rtl;
    
    }
}