.row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width:100%;
    margin:2% auto;
}
.slick-arrow.slick-next {
  display: flex !important; /* Use !important to override any existing styles */
}
.slick-arrow.slick-prev {
  display: flex !important; /* Use !important to override any existing styles */
}
.title {
    font-family: "AssistantR";
    font-size: 3.5rem;
  direction:rtl;
    color: #000000;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.286);
    padding-top:2%
  
  }
  .container{
    margin:5% auto
  }
  .sliderContainer {

    width:65%;
    margin:3% auto;
 
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 350px) {
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width:100%;
        flex-wrap: wrap;
        margin:2% auto;
    }
 
    .title {
        font-family: "AssistantR";
        font-size: 2rem;
      direction:rtl;
        color: #000000;
        text-align: center;
        padding-top:2%
      
      }
  }
  @media only screen   and (min-width: 350px) and (max-width: 450px) {
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width:100%;
        flex-wrap: wrap;
        margin:2% auto;
    }
  
    .title {
        font-family: "AssistantR";
        font-size: 2.3rem;
      direction:rtl;
      width:85%;
    margin:0 auto;
        color: #000000;
        text-align: center;
        padding-top:2%
      
      }
  }
  @media only screen   and (min-width: 450px) and (max-width: 550px) {
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width:100%;
        flex-wrap: wrap;
        margin:2% auto;
    }
    
    .title {
        font-family: "AssistantR";
        font-size: 2.5rem;
      direction:rtl;
        color: #000000;
        text-align: center;
        padding-top:2%
      
      }
  }
  @media only screen   and (min-width: 550px) and (max-width: 750px) {
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width:100%;
        flex-wrap: wrap;
        margin:2% auto;
    }
    .title {
        font-family: "AssistantR";
        font-size: 2.8rem;
      direction:rtl;
        color: #000000;
        text-align: center;
        padding-top:2%
      
      }
    
  }
  @media only screen   and (min-width: 750px) and (max-width: 950px) {
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width:100%;
        flex-wrap: wrap;
        margin:2% auto;
    }
   
    .title {
        font-family: "AssistantR";
        font-size: 3rem;
      direction:rtl;
        color: #000000;
        text-align: center;
        padding-top:2%
      
      }
  }
  @media only screen   and (min-width: 950px) and (max-width:1250px) {
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width:100%;
        flex-wrap: wrap;
        margin:2% auto;
    }

    .title {
        font-family: "AssistantR";
        font-size: 3.2rem;
      direction:rtl;
        color: #000000;
        text-align: center;
        padding-top:2%
      
      }
  }