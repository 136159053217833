.wrapper {
    display: flex;
    flex-direction: column;
    margin: 2% auto;
    width:60%;
    justify-content: center;
    align-items: center;
}

.column {
    display: flex;
    flex-direction: row;
    margin: 2% auto;
    justify-content: space-between;
    align-items: center;
    width: 80%;
}

.column.reverse {
    flex-direction: row-reverse;
}

.image {
    width: 200px;
    height: 200px;

    margin: 2% auto;
    background: whitesmoke;
    object-fit: contain;

}

.description {
    font-size: 1.2rem;
    font-family: "AssistantR";
    text-align: right;
    margin: 2% auto;
    width:90%;
    direction: rtl;
    text-shadow: 1px 2px 4px rgba(154, 154, 154, 0.2);
}



cssCopy.wrapper {
    display: flex;
    flex-direction: column;
    margin: 2% auto;
    width: 60%;
    justify-content: center;
    align-items: center;
}

.column {
    display: flex;
    flex-direction: row;
    margin: 2% auto;
    justify-content: space-between;
    align-items: center;
    width: 80%;
}

.column.reverse {
    flex-direction: row-reverse;
}

.image {
    width: 200px;
    height: 200px;
    margin: 2% auto;
    background: whitesmoke;
    object-fit: contain;
}

.description {
    font-size: 1.2rem;
    font-family: "AssistantR";
    text-align: right;
    margin: 2% auto;
    width: 90%;
    direction: rtl;
    text-shadow: 1px 2px 4px rgba(154, 154, 154, 0.2);
}

.title {
    font-size: 2.5rem;
    font-family: "AssistantR";
    text-align: right;
    margin: 1% auto;
    width: 90%;
    direction: rtl;
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
}

@media only screen and (min-width: 1050px) and (max-width: 1250px) {
    .wrapper { width: 80%; }
    .column { width: 90%; }
    .image { width: 180px; height: 180px; }
    .description { font-size: 1.4rem; }
    .title { font-size: 2.3rem; }
}

@media only screen and (min-width: 850px) and (max-width: 1050px) {
    .wrapper { width: 90%; }
    .image { width: 160px; height: 160px; }
    .description { font-size: 1.25rem; }
    .title { font-size: 2.1rem; }
}

@media only screen and (min-width: 650px) and (max-width: 850px) {
    .wrapper { width: 90%; }
    .image { width: 140px; height: 140px; }
    .description { font-size: 1.2rem; }
    .title { font-size: 1.9rem; }
}

@media only screen and (min-width: 550px) and (max-width: 650px) {
    .wrapper { width: 95%; }
    .column { width: 95%; }
    .image { width: 120px; height: 120px; }
    .description { font-size: 1.1rem; }
    .title { font-size: 1.7rem; }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
    .image { width: 110px; height: 110px; }
    .description { font-size: 1rem; width:90%; }
    .title { font-size: 1.2rem;width:90%;  }
    .wrapper { width: 100%; }
    .column.reverse,.column{
        justify-content: space-evenly;
        width:95%;
    }
}
@media only screen and (min-width: 350px) and (max-width: 450px) {
    .image { width: 100px; height: 100px; }
    .description { font-size: 0.9rem; width:90%; }
    .title { font-size: 1.2rem;width:90%;  }
    .wrapper { width: 100%; }
    .column.reverse,.column{
        justify-content: space-evenly;
        width:95%;
    }
}


@media only screen and (max-width: 350px) {
    .image { width: 90px; height: 90px; }
    .description { font-size: 0.8rem; width:95%; }
    .title { font-size: 1.2rem;width:95%;  }
    .wrapper { width: 100%; }
    .column.reverse,.column{
        justify-content: space-evenly;
        width:95%;
    }
}



