.title{
    font-size:3.5rem;
    font-family: "AssistantR";
    text-align: center;
    margin:1% auto;
    direction:rtl;
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
}
.center{
    width:30%;
    display: flex;
    justify-content: center;
    margin:2% auto;
}
.line {
    width: 250px;
    height: 15px;
    background: linear-gradient(90deg,#f2b1c8, #E5719A, #f2b1c8);
    margin:10px auto
}
.bold{
    margin:1% auto;
    text-align: center;
    font-size:2rem;
    direction: rtl;
    font-family: "Assistant";
}

.description{
    font-size:1.5rem;
    font-family: "AssistantR";
    text-align: center;
    margin:1% auto;
    direction: rtl;
    width:30%;
    text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
}

.image{
    width:100%;
    object-fit: contain;
}



@media only screen and (max-width: 350px){
    .title{
        font-size:2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        direction:rtl;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
    }

    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:1% auto;
        justify-content: space-evenly;
    }
    .description{
        font-size:1.15rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        width:90%;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
    }

    .center{
        display: flex;
        margin:0 auto;
        width:90%;
        justify-content: center;
    }
}

@media only screen and (min-width: 350px) and (max-width: 450px){
    .title{
        font-size:2.3rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        direction:rtl;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:2% auto;
        justify-content: space-evenly;
    }
    .description{
        font-size:1.2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        width:90%;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
    }
 


    .center{
        display: flex;
        margin:0 auto;
        width:85%;
        justify-content: center;
    }
}
@media only screen and (min-width: 450px) and (max-width: 550px){
    .title{
        font-size:2.5rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        direction:rtl;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
    }

    .description{
        font-size:1.25rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        width:90%;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
    }


    .center{
        display: flex;
        width:70%;
        margin:0 auto;
        justify-content: center;
    }
}
@media only screen and (min-width: 550px) and (max-width: 650px){
    .title{
        font-size:2.8rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        direction:rtl;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
    }

    .description{
        font-size:1.4rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        width:90%;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
    }

    .center{
        display: flex;
        margin:0 auto;
        width:60%;
        justify-content: center;
    }
}
@media only screen and (min-width: 650px) and (max-width: 750px){
    .title{
        font-size:3rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        direction:rtl;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
    }

    .description{
        font-size:1.5rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        width:90%;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
    }

    .center{
        display: flex;
        margin:0 auto;
        width:50%;
        justify-content: center;
    }
}
@media only screen and (min-width: 750px) and (max-width: 950px){
    .title{
        font-size:3.3rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        direction:rtl;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
    }

    .description{
        font-size:1.5rem;
        font-family: "AssistantR";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        width:80%;
        text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
    }
  
    .center{
        display: flex;
        width:40%;
        margin:0 auto;
        justify-content: center;
    }
}
