.box{
    height:400px;
    width:300px;
    box-shadow: 0 0 9px 1px #5f5f5f;
    display: flex;
    flex-direction: column;
  margin:2% auto;
  background: whitesmoke;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 25px;
  padding:8px 16px;
  justify-content: space-evenly;

}
.title {
    color: rgb(0, 0, 0);
 font-size: 2rem;
    text-shadow: 2px 2px 4px rgba(84, 84, 84, 0.7);
    text-align: center;
    font-family: "Assistant";
}
.description {
    color: rgb(0, 0, 0);
 font-size: 1.1rem;
    text-shadow: 2px 2px 4px rgba(84, 84, 84, 0.2);
    text-align: center;
    font-family: "AssistantR";
}
.center{
width: 50%;
display: flex;
justify-content: center;
margin:2% auto;
}
@media only screen and (max-width: 350px){


    .box{
        height:350px;
        width:260px;
         box-shadow: 0 0 9px 1px #5f5f5f;
        display: flex;
        flex-direction: column;
      margin:3% auto;
       background: whitesmoke;
      border-radius: 15px;
      padding:5px 10px;
      justify-content: space-evenly;
    
    }
    .title {
        color: rgb(0, 0, 0);
     font-size: 1.8rem;
        text-shadow: 2px 2px 4px rgba(84, 84, 84, 0.7);
        text-align: center;
        font-family: "Assistant";
    }
    .description {
        color: rgb(0, 0, 0);
     font-size: 1rem;
        text-shadow: 2px 2px 4px rgba(84, 84, 84, 0.2);
        text-align: center;
        font-family: "AssistantR";
    }
    .center{
    width: 50%;
    display: flex;
    justify-content: center;
    margin:2% auto;
    }

}
@media only screen  and (min-width: 350px) and (max-width: 450px){


    .box{
        height:400px;
        width:300px;
         box-shadow: 0 0 9px 1px #5f5f5f;
        display: flex;
        flex-direction: column;
      margin:3% auto;
       background: whitesmoke;
      border-radius: 15px;
      padding:5px 10px;
      justify-content: space-evenly;
    
    }
    .title {
        color: rgb(0, 0, 0);
     font-size: 2rem;
        text-shadow: 2px 2px 4px rgba(84, 84, 84, 0.7);
        text-align: center;
        font-family: "Assistant";
    }
    .description {
        color: rgb(0, 0, 0);
     font-size: 1rem;
        text-shadow: 2px 2px 4px rgba(84, 84, 84, 0.2);
        text-align: center;
        font-family: "AssistantR";
    }
    .center{
    width: 50%;
    display: flex;
    justify-content: center;
    margin:2% auto;
    }

}