.center {
    margin: 20px auto;
    width:20%;
    justify-content: center;
    
  }
  
  .image{
    width: 100%;

    object-fit: contain;
   
  }

  @media (max-width:350px) {
    .center {
      width: 85%;
    }
  }
  
  @media (min-width: 350px) and (max-width:450px) {
    .center {
      width: 70%;
    }
  }

  @media (min-width: 450px) and (max-width:550px) {
    .center {
      width: 60%;
    }
  }
  @media (min-width: 550px) and (max-width:650px) {
    .center {
      width: 50%;
    }
  }
  @media (min-width: 650px) and (max-width:750px) {
    .center {
      width: 45%;
    }
  }
  @media (min-width: 750px) and (max-width:850px) {
    .center {
      width: 50%;
    }
  }
  

